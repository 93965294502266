<template>
  <div style="padding-bottom: 30px;">
    <v-data-table
      :headers="headers"
      :items="shows"
      :loading="loading"
      :itemsPerPage="itemsPerPage"
      item-key="ID"
      class="page__table"
      hide-default-footer
    >
      <template v-slot:top>
        <custom-table-title 
          :onAdd="handleAdd"
          :title="config.Show ? 'Shows' : 'Actividades'"
          icon="mdi-ticket"
          :onSearch="handleSearch"
        />
      </template>
      <template v-slot:[`item.ident`]="{ item }">
        <a @click="() => { handleEdit(item.ID) }">{{ getTranslation(item.Title, locale) || item.ID }}</a>
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{convertCategory(item.CategoryID, locale)}}
      </template>
      <template v-slot:[`item.hide`]="{ item }">
        <v-icon 
          :color="item.Hide ? 'red' : 'green'" 
          @click="handleHideActivity(item.ID, item.Hide)"
        >
          {{ item.Hide ? 'mdi-eye-off' : 'mdi-eye' }}
        </v-icon>
      </template>
      <template v-slot:[`item.availability`]="{ item }">
        {{ item.AvailabilityCount > 0 ? item.AvailabilityCount : ''}}
      </template>
      <!--template v-slot:body="props">

      </template-->
    </v-data-table>
    <pagination
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :count="count"
      :onGetData="handleGetData"
      :locale="locale"
    />
    <form-event
      :timezone="timezone"
      :languages="languages"
      :fromManager="true"
      :value="editedItem"
      :onClose="handleCloseForm"
      :contentTypeID="contentTypeID"
      :locale="locale"
      :workspaceID="workspaceID"
    />
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import CustomTableTitle from '@/components/customTable/CustomTableTitle'
import Pagination from '@/components/customTable/Pagination'
import FormEvent from './Form'
export default {
  components: {
    CustomTableTitle,
    FormEvent,
    Pagination,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
      requried: true,
    },
    contentTypeID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onEdit: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    loading: false,
    search: '',
    startIndex: 0,
    itemsPerPage: 10,
    shows: [],
    count: null,
    editedItem: null,
  }),
  computed: {
    headers () {
      return [
        { text: 'Evento', value: 'ident',  sortable: false, align: 'left', },
        { text: 'Categoría', value: 'category',  sortable: false, align: 'left', },
        { text: 'Ocultar/mostrar', value: 'hide',  sortable: false, align: 'center', },
        { text: 'Programaciones', value: 'availability',  sortable: false, align: 'center', },
      ]
    },
  },
  watch: {
    contentTypeDataID () {
      this.handleGetData(0)
    },
  },
  mounted () {
    this.handleGetData(0)
  },
  methods: {
    convertCategory (v, locale) {
      console.log(v, locale)
      return null//this.contentTypeData.Categories.filter(x => x.ID === v).map(item => this.getTranslation(item.Name, locale)).shift()
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleSearch (v) {
      this.search = v
      this.handleGetData(0)
    },
    handleGetData (startIndex) {
      this.loading = true
      if (startIndex >= 0 ) this.startIndex = startIndex
      const url = `v1/private/events-manager/${this.contentTypeID}`
      api.getAll ('dviewcontent', url, this.startIndex, this.itemsPerPage, this.search)
        .then(response => {
          if (response) {
            this.count = response.count
            this.shows = response.data.map (item => {
              item.Title = JSON.parse(item.Title)
              item.Description = JSON.parse(item.Description)
              item.Place = JSON.parse(item.Place)
              
              return item
            })
          }
          this.loading = false
        })
        .catch (error => {
          console.log(error)
          this.loading = false
        })
    },
    handleCloseForm (refresh) {
      if (refresh) this.handleGetData()
      this.editedItem = null
    },
    handleEdit (v) {
      this.loading = true
      const url = `v1/private/events-manager/${this.contentTypeID}/`
      api.getItem ('dviewcontent', url, v)
        .then(response => {
          if (response) {
            response.Title = response.Title ? JSON.parse(response.Title) : null
            response.Description = response.Description ? JSON.parse(response.Description) : null
            response.Place = response.Place ? JSON.parse(response.Place) : null
          }
          this.editedItem = response
          this.loading = false
        })
        .catch (error => {
          console.log(error)
          this.loading = false
        })
    },
    handleAdd (v) {
      this.editedItem = {
        //CategoryID: this.contentTypeData.Categories[0].ID,
        Title: { es: '' },
        Place: { es: ''},
        Time: 960,
        TimeEnd: 1020,
        date: v,
        Availabilities: [],
      }
    },
    handleHideActivity(id, hide) {
      const url = `v1/private/events-manager/${this.contentTypeID}/hide-activity`
      api.put ('dviewcontent', url, {ID: id, Hide: hide ? false : true })
        .then(() => {
          this.handleGetData()
        })
        .catch (error => {
          console.log(error)
        })
    },
  },
}
</script>
