var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-bottom":"30px"}},[_c('v-data-table',{staticClass:"page__table",attrs:{"headers":_vm.headers,"items":_vm.shows,"loading":_vm.loading,"itemsPerPage":_vm.itemsPerPage,"item-key":"ID","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('custom-table-title',{attrs:{"onAdd":_vm.handleAdd,"title":_vm.config.Show ? 'Shows' : 'Actividades',"icon":"mdi-ticket","onSearch":_vm.handleSearch}})]},proxy:true},{key:"item.ident",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function () { _vm.handleEdit(item.ID) }}},[_vm._v(_vm._s(_vm.getTranslation(item.Title, _vm.locale) || item.ID))])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCategory(item.CategoryID, _vm.locale))+" ")]}},{key:"item.hide",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.Hide ? 'red' : 'green'},on:{"click":function($event){return _vm.handleHideActivity(item.ID, item.Hide)}}},[_vm._v(" "+_vm._s(item.Hide ? 'mdi-eye-off' : 'mdi-eye')+" ")])]}},{key:"item.availability",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.AvailabilityCount > 0 ? item.AvailabilityCount : '')+" ")]}}],null,true)}),_c('pagination',{attrs:{"startIndex":_vm.startIndex,"itemsPerPage":_vm.itemsPerPage,"count":_vm.count,"onGetData":_vm.handleGetData,"locale":_vm.locale}}),_c('form-event',{attrs:{"timezone":_vm.timezone,"languages":_vm.languages,"fromManager":true,"value":_vm.editedItem,"onClose":_vm.handleCloseForm,"contentTypeID":_vm.contentTypeID,"locale":_vm.locale,"workspaceID":_vm.workspaceID}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }